<template>
    <div class="container">
        <qy-top-nav title="密码管理" />
        <van-cell-group>
            <van-cell title="修改登录密码" is-link to="/editPwd"/>
            <van-cell title="忘记登录密码" is-link to="/forgetPwd"/>
        </van-cell-group>
    </div>
</template>

<script>
    export default {
        name: "Password",
        data() {
            return {

            }
        },
    }
</script>

<style scoped>

</style>